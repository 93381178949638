import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import NavBar from "../navBar/NavBar";
import Container from "../container/Container";
import {RequireAuth} from "react-auth-kit";
import LoginComponent from "../login/LoginComponent";
import Contas from "../contas/Contas";
import ContasNew from "../contas/ContasNew";
import Pix from "../pix/Pix";
import {useEffect, useState} from "react";
import NotAllowed from "../notAllowed/NotAllowed";
import QrCode from "../pix/QrCode";
import Dashboard from "../dashboard/Dashboard";
import CustomTable from "../pix/CustomTable";


function RoutesComponent() {

    const [allowRoute, setAllowRoute] = useState(false);
    const roles = localStorage.getItem('roles');


    useEffect(() => {

        if (roles && roles.includes("ADMIN")) {
            setAllowRoute(true);
        }
    },);


    return (
        <div>
            <Router>
                <NavBar/>
                <Container customClass="full">
                    <Routes>
                        <Route path={'/login'} element={<LoginComponent/>}/>

                        <Route path={'/'} element={
                            <RequireAuth loginPath={'/login'}>
                                <Dashboard/>
                            </RequireAuth>
                        }/>

                        <Route path={'/contas'} element={allowRoute ?
                            <RequireAuth loginPath={'/login'}>
                                <Contas/>
                            </RequireAuth> : <NotAllowed/>
                        }/>

                        <Route path={'/contasNew'} element={allowRoute ?
                            <RequireAuth loginPath={'/login'}>
                                <ContasNew/>
                            </RequireAuth> : <NotAllowed/>
                        }/>

                        <Route path={'/pix'} element={
                            <RequireAuth loginPath={'/pix'}>
                                <CustomTable/>
                            </RequireAuth>
                        }/>

                        <Route path={'/qrcode'} element={
                            <RequireAuth loginPath={'/qrcode'}>
                                <QrCode/>
                            </RequireAuth>
                        }/>

                        <Route path="/login" element={<LoginComponent/>}></Route>
                    </Routes>
                </Container>
            </Router>
        </div>
    )
}

export default RoutesComponent
