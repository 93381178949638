import React from 'react';
import MaskedInput from 'react-text-mask';

const cpfMask = [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
const cnpjMask = [/[0-9]/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];

const isCpf = (value) => value.length <= 11;

function MaskedInputComponent({value}) {
    const mask = isCpf(value.replace(/[^\d]/g, '')) ? cpfMask : cnpjMask;
    return (
        <MaskedInput
            mask={mask}
            value={value}
            guide={false}
            render={(ref, props) => (
                <input ref={ref} {...props}
                       readOnly={true}
                       style={{
                           border: 'none',
                           pointerEvents: 'none',
                           backgroundColor: 'transparent',
                           color: 'inherit',
                           textAlign: 'left',
                       }}/>
            )}
        />
    );
}

export default MaskedInputComponent;
