export const tipos = [
    '',
    'Compras',
    'Impostos',
    'Folha Pagamento',
    'Excepcional',
    'Operacional',
    'Acerto Demissão',
    'Não Classificado',
    'Investimento',
    'Prolabore',
    'Retirada dos Socios',
    'Comissão',
    'Taxas',
    'TARIFAS BANCARIAS',
    'Combustivel',
    'Compras Locais',
    'DOAÇOES',
    'EQUIPAMENTOS',
    'COMPRAS LABORATORIO',
    'Imposto sobre folha',
    'Material de Escritorio',
    'Manutenção',
    'Mercado',
    'Despesa fixas'];

export const formasDePagamento = [
    'Dinheiro',
    'PIX',
    'Boleto No Itau',
    'Pix Conta Pessoal Juliano',
    'CDB Itau',
    'Salario Pelo ITAU',
    'DEBITO AUTOMATICO']
