export const pages = [

    {
        page: "Dashboard",
        route: "/"
    },
    {
        page: "Pix",
        route: "/pix"
    },
    {
        page: "Qrcode",
        route: "/qrcode"
    }
]
