import {useEffect, useRef, useState} from "react";
import 'ag-grid-community/styles//ag-grid.css';
import 'ag-grid-community/styles//ag-theme-alpine.css';
import {AgGridReact} from "ag-grid-react";
import {columnDefs, defaultColDef, localeText} from "./columnDefs";
import {Boletos, update} from "./services/Contas.service";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import styles from "./contas.module.css";


function Contas() {

    const gridApiRef = useRef(null);

    const [selectedRows, setSelectedRows] = useState();


    const [selectedRowsTotal, setSelectedRowsTotal] = useState(0);

    const [selectedMonth, setSelectedMonth] = useState('');

    const months = [
        {value: 1, label: 'Janeiro'},
        {value: 2, label: 'Fevereiro'},
        {value: 3, label: 'Março'},
        {value: 4, label: 'Abril'},
        {value: 5, label: 'Maio'},
        {value: 6, label: 'Junho'},
        {value: 7, label: 'Julho'},
        {value: 8, label: 'Agosto'},
        {value: 9, label: 'Setembro'},
        {value: 10, label: 'Outubro'},
        {value: 11, label: 'Novembro'},
        {value: 12, label: 'Dezembro'}
    ];


    const [rowData, setRowData] = useState([]);

    const fetchData = async () => {
        try {
            const {firstDayOfMonth, lastDayOfMonth} = getCurrentMonthDates();
            const fetchedData = await Boletos(formatDate(firstDayOfMonth), formatDate(lastDayOfMonth));
            const jsonData = await fetchedData.json()

            const updatedData = jsonData.map(item => {
                return {
                    ...item,
                    valor: formatCurrencyBRL(item.valor)
                };
            });

            setRowData(updatedData); // Set the fetched data to the rowData state
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const formatCurrencyBRL = (value) => {
        if (value === null) {
            value = 0.00
        }
        return value.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            style: "currency",
            currency: "BRL"
        });
    };

    useEffect(() => {
        fetchData();
    }, []);


    const getCurrentMonthDates = () => {
        let today = new Date();

        if (selectedMonth !== "") {
            const year = today.getFullYear();
            const date = new Date(year, selectedMonth - 1, 1);
            today = date;
        }

        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

        return {firstDayOfMonth, lastDayOfMonth};
    };

    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year

        return `${day}-${month}-${year}`;
    };

    const onCellEditingStarted = (event) => {
    };

    const onCellEditingStopped = async (event) => {

        const column = event.column.colId;
        const newValue = event.newValue;
        const data = event.data;

        if (selectedRows === undefined) {
            await update([data], newValue, column)
        } else {
            await update(selectedRows, newValue, column)
            setSelectedRows(undefined)
        }
        setSelectedRowsTotal(0);
        await fetchData();
    };

    const isToday = (someDate) => {
        const today = new Date();
        const dateToCheck = new Date(someDate);
        return dateToCheck.getDate() === today.getDate() &&
            dateToCheck.getMonth() === today.getMonth() &&
            dateToCheck.getFullYear() === today.getFullYear();
    };

    const getRowStyle = (params) => {
        const status = params.data.status;
        const vencimento = params.data.vencimento;
        if (status == true) {
            return {backgroundColor: 'lightblue'};
        } else if (status == false && isToday(vencimento)) {
            return {backgroundColor: 'lightpink'};
        }
        return null;
    };

    const parseCurrencyBRL = (valueStr) => {
        const numericValue = parseFloat(valueStr.replace(/[^\d.,-]/g, '').replace(',', '.'));
        const res = isNaN(numericValue) ? null : numericValue;
        return res;
    };

    const handleSelectionChanged = (event) => {

        if (gridApiRef.current) {
            const filteredRows = [];

            gridApiRef.current.forEachNodeAfterFilter(node => {
                filteredRows.push(node.data);
            });
            const selectedRowsLocal = gridApiRef.current.getSelectedRows()
            if (selectedRowsLocal.length > filteredRows.length) {
                const parsedFilteredRows = filteredRows.map(item => {
                    return {
                        ...item,
                        valor: parseCurrencyBRL(item.valor)
                    };
                });

                const totalValor = parsedFilteredRows.reduce((total, item) => total + item.valor, 0);
                setSelectedRowsTotal(totalValor)
                setSelectedRows(filteredRows);
            } else {
                const parsedSelectedRowsLocal = selectedRowsLocal.map(item => {
                    return {
                        ...item,
                        valor: parseCurrencyBRL(item.valor)
                    };
                });
                const totalValor = parsedSelectedRowsLocal.reduce((total, item) => total + item.valor, 0);
                setSelectedRowsTotal(totalValor)
                setSelectedRows(selectedRowsLocal);
            }
        }
    };


    useEffect(() => {
    }, [selectedRowsTotal]);

    useEffect(() => {
    }, [selectedRows]);

    useEffect(() => {
        fetchData();
    }, [selectedMonth]);


    const gridOptions = {
        onSelectionChanged: handleSelectionChanged,
        suppressCellSelection: false,
        suppressRowClickSelection: true,
        enableRangeSelection: true,
        enableCellTextSelection: true
    };

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    return (
        <>

            <div className={styles.options}>
                <div className={styles.optionsInfos}>
                    <p> Total Selecionado: R$ {selectedRowsTotal.toFixed(2)}</p>
                </div>
                <div>
                    <FormControl sx={{m: 1, minWidth: 120}} size="small">
                        <InputLabel id="select-mes-label" style={{color: 'white'}}>Mês</InputLabel>
                        <Select
                            sx={{
                                height: '2.5rem',
                                color: 'white',
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'white'
                                },
                                '& .MuiSvgIcon-root': {
                                    color: 'white'
                                }
                            }}
                            labelId="select-mes"
                            id="select-mes"
                            value={selectedMonth}
                            label="mes"
                            onChange={handleMonthChange}
                        >
                            {months.map(item => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.label}
                                </MenuItem>
                            ))}


                        </Select>
                    </FormControl>

                </div>

            </div>
            <div className="ag-theme-alpine m-4 ag-theme-alpine" style={{height: '100%', width: '100%'}}>
                <AgGridReact
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    rowData={rowData}
                    rowDragManaged={true}
                    groupDisplayType={'groupRows'}
                    animateRows={true}
                    localeText={localeText}
                    onCellEditingStarted={onCellEditingStarted}
                    onCellEditingStopped={onCellEditingStopped}
                    getRowStyle={getRowStyle}
                    rowSelection={'multiple'}
                    domLayout='autoHeight'
                    groupSelectsChildren={true}
                    suppressAggFuncInHeader={true}
                    gridOptions={gridOptions}
                    onGridReady={(params) => (gridApiRef.current = params.api)}
                />
            </div>

        </>
    )
}

export default Contas
