import React, {useEffect, useState} from 'react';
import {
    MaterialReactTable,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
    MRT_ToggleFiltersButton,
    MRT_ToggleFullScreenButton,
    MRT_ToggleGlobalFilterButton,
    useMaterialReactTable,
} from 'material-react-table';
import {getPix} from "./services/Pix.service";
import styles from "./pix.module.css";
import load from "../../assets/load.gif";
import {Box, IconButton} from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {getDate15DaysAgo} from "../utils/Utils";
import {getColumns} from "./columnDefsMaterialTable";


function CustomTable() {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showValues, setShowValues] = useState(true);
    const columns = getColumns(showValues);

    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);
                const fetchedData = await getPix(getDate15DaysAgo());
                const jsonData = await fetchedData.json();
                setData(jsonData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        fetchData();
    }, []);

    const handleToggleValues = () => {
        setShowValues(!showValues);
    };

    const table = useMaterialReactTable({
        data,
        columns,
        renderToolbarInternalActions: ({table}) => (
            <Box>
                <MRT_ToggleGlobalFilterButton table={table}/>
                <IconButton onClick={handleToggleValues}>
                    {showValues ? <VisibilityIcon/> : <VisibilityOffIcon/>}
                </IconButton>
                <MRT_ToggleDensePaddingButton table={table}/>
                <MRT_ToggleFiltersButton table={table}/>
                <MRT_ShowHideColumnsButton table={table}/>
                <MRT_ToggleFullScreenButton table={table}/>
            </Box>
        ),
    });

    return (
        <>
            {loading ? (
                <div className={styles.loadingPage}>
                    <img className={styles.loadingImage} alt="loading" src={load}/>
                </div>
            ) : (
                <div className={styles.customTable}>
                    <MaterialReactTable table={table} className={styles.customTable}/>
                </div>
            )}
        </>
    );
}

export default CustomTable;
