import {tipos, formasDePagamento} from "./contatantes"

const conciliadoValueFormatter = params => {
    return params.value ? 'SIM' : 'Não';
};

// function formatCurrency(params) {
//     const value = params.value == null ? 0 : params.value;
//     return value.toLocaleString('pt-BR', {
//         minimumFractionDigits: 2,
//         maximumFractionDigits: 2,
//         style: "currency",
//         currency: "BRL"
//     });
// }

const filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString = cellValue;
        const dateParts = dateAsString.split('/');
        const cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0])
        );

        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
        }

        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        }

        if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
    },
};

export const columnDefs = [

    {headerCheckboxSelection: true, checkboxSelection: true, width: 20, floatingFilter: false},
    {headerName: 'id', field: 'id', hide: true},
    {
        headerName: 'ativo',
        width: 90,
        valueFormatter: conciliadoValueFormatter,
        cellEditor: 'agSelectCellEditor',
        field: 'ativo',
        cellEditorParams: {
            values: [true, false],
        }
    },
    {headerName: 'N. Documento', field: 'numeroDocumento', editable: false, width: 150},
    {headerName: 'CNPJ emitente', field: 'CNPJEmitente', editable: false, width: 220},
    {headerName: 'Emitente', field: 'emitente', editable: false, width: 300},

    {
        headerName: 'Vencimento',
        field: 'vencimentoStr',
        filter: 'agDateColumnFilter',
        editable: false,
        filterParams: filterParams,
        width: 170
    },
    {
        headerName: 'Valor',
        field: 'valor',
        editable: true,
        width: 150,
        filter: 'agNumberColumnFilter',
        // valueFormatter: params => {
        //     return formatCurrency(params)
        // },
    },
    {
        headerName: 'Tipo',
        cellEditor: 'agSelectCellEditor',
        field: 'tipo',
        width: 150,
        cellEditorParams: {
            values: tipos,
        }
    },

    {
        headerName: 'Forma de Pagamento',
        cellEditor: 'agSelectCellEditor',
        field: 'formaDePagamento',
        width: 180,
        cellEditorParams: {
            values: formasDePagamento,
        }
    },
    {
        headerName: 'Pago',
        width: 85,
        valueFormatter: conciliadoValueFormatter,
        cellEditor: 'agSelectCellEditor',
        field: 'status',
        cellEditorParams: {
            values: [true, false],
        }
    },
    {
        headerName: 'Conciliado',
        width: 96,
        valueFormatter: conciliadoValueFormatter,
        cellEditor: 'agSelectCellEditor',
        field: 'conciliado',
        cellEditorParams: {
            values: [true, false],
        }
    },

    {headerName: 'Observações', field: 'observacoes', editable: true},

];

export const localeText = {
    // Number Filter & Text Filter
    filterOoo: 'Filtro...',
    equals: 'Igual',
    notEqual: 'Diferente',
    blank: 'Em Branco',
    notBlank: 'Não Em Branco',
    empty: 'Escolha Um',

    // Number Filter
    lessThan: 'Menor que',
    greaterThan: 'Maior que',
    lessThanOrEqual: 'Less than or equal',
    greaterThanOrEqual: 'Greater than or equal',
    inRange: 'Data Rage',
    inRangeStart: 'de',
    inRangeEnd: 'para',

    // Text Filter
    contains: 'Contém',
    notContains: 'Não Contém',
    startsWith: 'Inicia com',
    endsWith: 'Termina com',

    // Filter Conditions
    andCondition: 'E',
    orCondition: 'Ou',
};


export const defaultColDef = {
    sortable: true,
    editable: true,
    filter: true,
    resizable: true,
    floatingFilter: true
}
