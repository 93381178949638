import {Box, Button, Checkbox, FormControlLabel, TextField, Typography} from "@mui/material";
import styles from "./LoginComponent.module.css";
import {useSignIn} from "react-auth-kit";
import {useNavigate} from 'react-router-dom';
import jwt_decode from "jwt-decode";
import {useState} from "react";
import {getUserDetails} from './Login.service'


function LoginComponent() {

    const baseUrl = process.env.REACT_APP_API_CONTAS_URL

    const signIn = useSignIn();

    const navigate = useNavigate();

    const [unauthorized, setUnauthorized] = useState(false);


    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const email = data.get("email");
        const password = data.get("password");
        const url = `${baseUrl}/getToken`;

        const body = {
            login: email,
            password: password
        };

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        });

        if (response.status === 200) {
            const res = await response.json();
            const tokenDecoded = jwt_decode(res.token)

            const login = tokenDecoded.sub;
            const token = res.token;

            const userDetails = await getUserDetails(login, token);

            if (userDetails.status === 200) {
                const userDetailsBody = await userDetails.json();

                if (tokenDecoded) {
                    signIn({
                        token: token,
                        expiresIn: 24,
                        tokenType: 'Bearer',
                        authState: {sub: tokenDecoded.sub}
                    })
                    localStorage.setItem('token', res.token);
                    localStorage.setItem('userInitials', getInitials(userDetailsBody.name));
                    localStorage.setItem('roles', userDetailsBody.roles)
                    navigate('/', {replace: true});
                }
            } else {
                setUnauthorized(true);
            }

        } else {
            setUnauthorized(true);
        }
    }

    function getInitials(fullName) {
        const parts = fullName.split(' ');
        let initials = '';

        if (parts.length > 0) {
            initials += parts[0][0];
            if (parts.length > 1) {
                initials += parts[parts.length - 1][0];
            }
        }

        return initials;
    }

    return (

        <div className={styles.loginFormContainer}>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                <Typography component="h1" variant="h5">
                    Login
                    {unauthorized ? (
                        <p>Unauthorized</p>) : null}
                </Typography>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                />
                <FormControlLabel
                    control={<Checkbox value="remember" color="primary"/>}
                    label="Remember me"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{mt: 3, mb: 2}}
                >
                    Logar
                </Button>
            </Box>
        </div>
    )
}

export default LoginComponent
