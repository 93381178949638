import {Card, CardContent, Typography, Grid, Box} from '@mui/material';
import {BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import {AttachMoney, Event, AccessTime, MonetizationOn} from '@mui/icons-material';


const data = {
    recebimentosMes: 33,
    recebimentosHoje: 56,
    recebidosMes: [
        {name: 'Semana 1', recebidos: 400},
        {name: 'Semana 2', recebidos: 300},
        {name: 'Semana 3', recebidos: 600},
        {name: 'Semana 4', recebidos: 700},
        {name: 'Semana 1', recebidos: 400},
        {name: 'Semana 2', recebidos: 300},
        {name: 'Semana 3', recebidos: 600},
        {name: 'Semana 4', recebidos: 700},
        {name: 'Semana 1', recebidos: 400},
        {name: 'Semana 2', recebidos: 300},
        {name: 'Semana 3', recebidos: 600},
        {name: 'Semana 4', recebidos: 700},
        {name: 'Semana 1', recebidos: 400},
        {name: 'Semana 2', recebidos: 300},
        {name: 'Semana 3', recebidos: 600},
        {name: 'Semana 4', recebidos: 700},
        {name: 'Semana 1', recebidos: 400},
        {name: 'Semana 2', recebidos: 300},
        {name: 'Semana 3', recebidos: 600},
        {name: 'Semana 4', recebidos: 700},
        {name: 'Semana 1', recebidos: 400},
        {name: 'Semana 2', recebidos: 300},
        {name: 'Semana 3', recebidos: 600},
        {name: 'Semana 4', recebidos: 700},
        {name: 'Semana 1', recebidos: 400},
        {name: 'Semana 2', recebidos: 300},
        {name: 'Semana 3', recebidos: 600},
        {name: 'Semana 4', recebidos: 700},
    ],
    recebidosHoje: [
        {name: '00:00', recebidos: 10},
        {name: '03:00', recebidos: 8},
        {name: '06:00', recebidos: 12},
        {name: '09:00', recebidos: 6},
        {name: '12:00', recebidos: 14},
        {name: '15:00', recebidos: 18},
        {name: '18:00', recebidos: 16},
        {name: '21:00', recebidos: 22},
        {name: '00:00', recebidos: 10},
        {name: '03:00', recebidos: 8},
        {name: '06:00', recebidos: 12},
        {name: '09:00', recebidos: 6},
        {name: '12:00', recebidos: 14},
        {name: '15:00', recebidos: 18},
        {name: '18:00', recebidos: 16},
        {name: '21:00', recebidos: 22},
        {name: '00:00', recebidos: 10},
        {name: '03:00', recebidos: 8},
        {name: '06:00', recebidos: 12},
        {name: '09:00', recebidos: 6},
        {name: '12:00', recebidos: 14},
        {name: '15:00', recebidos: 18},
        {name: '18:00', recebidos: 16},
        {name: '21:00', recebidos: 22},
        {name: '00:00', recebidos: 10},
        {name: '03:00', recebidos: 8},
        {name: '06:00', recebidos: 12},
        {name: '09:00', recebidos: 6},
        {name: '12:00', recebidos: 14},
        {name: '15:00', recebidos: 18},
        {name: '18:00', recebidos: 16},
        {name: '21:00', recebidos: 22},
        {name: '00:00', recebidos: 10},
        {name: '03:00', recebidos: 8},
        {name: '06:00', recebidos: 12},
        {name: '09:00', recebidos: 6},
        {name: '12:00', recebidos: 14},
        {name: '15:00', recebidos: 18},
        {name: '18:00', recebidos: 16},
        {name: '21:00', recebidos: 22},
        {name: '00:00', recebidos: 10},
        {name: '03:00', recebidos: 8},
        {name: '06:00', recebidos: 12},
        {name: '09:00', recebidos: 6},
        {name: '12:00', recebidos: 14},
        {name: '15:00', recebidos: 18},
        {name: '18:00', recebidos: 16},
        {name: '21:00', recebidos: 22},
    ],
    vendasMes: 85,
    vendasHoje: 5,
    valorVendasMes: 28950,
    valorVendasHoje: 1950,
};

function Dashboard() {
    return (
        <Box p={2}>
            <Grid container spacing={2}>
                {/* Card de Recebimentos no Mês */}
                <Grid item xs={12} md={3}>
                    <Card>
                        <CardContent>
                            <Event sx={{fontSize: 50, color: '#5ca9ac'}}/>
                            <Typography variant="h6" gutterBottom>
                                Recebimentos no Mês
                            </Typography>
                            <Typography variant="h4">
                                {data.recebimentosMes}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Card de Recebimentos Hoje */}
                <Grid item xs={12} md={3}>
                    <Card>
                        <CardContent>
                            <AccessTime sx={{fontSize: 50, color: '#5ca9ac'}}/>
                            <Typography variant="h6" gutterBottom>
                                Recebimentos Hoje
                            </Typography>
                            <Typography variant="h4">
                                {data.recebimentosHoje}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Card de Vendas no Mês */}
                <Grid item xs={12} md={3}>
                    <Card>
                        <CardContent>
                            <MonetizationOn sx={{fontSize: 50, color: '#5ca9ac'}}/>
                            <Typography variant="h6" gutterBottom>
                                Vendas no Mês
                            </Typography>
                            <Typography variant="h4">
                                {data.vendasMes}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Card de Vendas Hoje */}
                <Grid item xs={12} md={3}>
                    <Card>
                        <CardContent>
                            <MonetizationOn sx={{fontSize: 50, color: '#5ca9ac'}}/>
                            <Typography variant="h6" gutterBottom>
                                Vendas Hoje
                            </Typography>
                            <Typography variant="h4">
                                {data.vendasHoje}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Card de Valor Total de Vendas no Mês */}
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <MonetizationOn sx={{fontSize: 50, color: '#5ca9ac'}}/>
                            <Typography variant="h6" gutterBottom>
                                Valor Total de Vendas no Mês
                            </Typography>
                            <Typography variant="h4">
                                R$ {data.valorVendasMes.toLocaleString('pt-BR')}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Card de Valor Total de Vendas Hoje */}
                <Grid item xs={12} md={6}>
                    <Card>
                        <CardContent>
                            <MonetizationOn sx={{fontSize: 50, color: '#5ca9ac'}}/>
                            <Typography variant="h6" gutterBottom>
                                Valor Total de Vendas Hoje
                            </Typography>
                            <Typography variant="h4">
                                R$ {data.valorVendasHoje.toLocaleString('pt-BR')}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Gráfico de Recebidos no Mês */}
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Recebidos no Mês
                            </Typography>
                            <Box height={300}>
                                <ResponsiveContainer>
                                    <BarChart data={data.recebidosMes}>
                                        <XAxis dataKey="name"/>
                                        <YAxis/>
                                        <Tooltip/>
                                        <Legend/>
                                        <Bar dataKey="recebidos" fill="#5ca9ac"/>
                                    </BarChart>
                                </ResponsiveContainer>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Gráfico de Recebidos Hoje */}
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Recebidos Hoje
                            </Typography>
                            <Box height={300}>
                                <ResponsiveContainer>
                                    <BarChart data={data.recebidosHoje}>
                                        <XAxis dataKey="name"/>
                                        <YAxis/>
                                        <Tooltip/>
                                        <Legend/>
                                        <Bar dataKey="recebidos" fill="#5ca9ac"/>
                                    </BarChart>
                                </ResponsiveContainer>
                            </Box>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Dashboard
