const baseUrl = process.env.REACT_APP_API_CONTAS_URL

const token = localStorage.getItem("token");

export const Boletos = async (startDate, endDate) => {

    const url = `${baseUrl}/v1/contas/listByDateRange?startDate=${startDate}&endDate=${endDate}`;
    return fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        }
    });
}

const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
};

export const insert = async (conta) => {
    conta.vencimentoStr = formatDate(conta.vencimentoStr)
    const url = `${baseUrl}/v1/contas/insert`;
    const body = {
        contasPagar: conta
    };

    return await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
        body: JSON.stringify(body)
    });
}

const parseCurrencyBRL = (currencyString) => {
    const numericString = currencyString.replace(/[^\d.,]/g, '');
    const numericValue = parseFloat(numericString.replace('.', '').replace(',', '.'));
    return isNaN(numericValue) ? 0.00 : numericValue;
    ;
};

export const update = async (boletos, newValue, field) => {

    // const token = localStorage.getItem("token");

    const updatedData = boletos.map(item => {
        return {
            ...item,
            valor: parseCurrencyBRL(item.valor)
        };
    });

    let newValueToUpdate = newValue;

    if (field == "valor") {
        newValueToUpdate = parseCurrencyBRL(newValue);
    }

    const url = `${baseUrl}/v1/contas/update`;
    const body = {
        contasPagar: updatedData,
        newValue: newValueToUpdate,
        field: field
    };

    return await fetch(url, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        },
        body: JSON.stringify(body)
    });
}
