import {Button, Container, MenuItem, TextField} from "@mui/material";
import {useState} from "react";
import {insert} from "./services/Contas.service";
import {formasDePagamento, tipos} from "./contatantes"
import styles from "./contas.module.css";

function ContasNew() {

    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };


    const [formData, setFormData] = useState({
        numeroDocumento: '',
        CNPJEmitente: '',
        emitente: '',
        vencimentoStr: getTodayDate(),
        valor: '',
        tipo: '',
        formaDePagamento: '',
        status: false,
        observacoes: '',
        conciliado: false,
        ativo: true,
    });

    const handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        const newValue = type === 'checkbox' ? checked : value;

        // If handling date input, format the date value correctly
        if (type === 'date') {
            const dateParts = value.split('-');
            const formattedDate = `${dateParts[0]}-${dateParts[1].padStart(2, '0')}-${dateParts[2].padStart(2, '0')}`;
            setFormData({...formData, [name]: formattedDate});
        } else {
            setFormData({...formData, [name]: newValue});
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await insert(formData);
        if (res.status === 200) {
            alert('Registro inserido com sucesso!');

            setFormData({
                numeroDocumento: '',
                CNPJEmitente: '',
                emitente: '',
                vencimentoStr: getTodayDate(),
                valor: '',
                tipo: '',
                formaDePagamento: '',
                status: false,
                observacoes: '',
                conciliado: false,
                ativo: true,
            });
        } else {
            alert('erro ao inserir o registro');
        }

    };

    return (
        <Container maxWidth="sm">

            <h2 className={styles.h2_header}>
                Sonifarma Consulta Pagamentos PIX
            </h2>

            <form onSubmit={handleSubmit}>
                <TextField label="Número do Documento" name="numeroDocumento" value={formData.numeroDocumento}
                           onChange={handleChange} fullWidth sx={{mb: 2}}/>

                <TextField label="CNPJ Emitente" name="CNPJEmitente" value={formData.CNPJEmitente}
                           onChange={handleChange} fullWidth sx={{mb: 2}}/>

                <TextField label="Emitente" name="emitente" value={formData.emitente} onChange={handleChange} fullWidth
                           sx={{mb: 2}} required/>

                <TextField type="date" label="Vencimento" name="vencimentoStr" onChange={handleChange} fullWidth
                           sx={{mb: 2}} value={formData.vencimentoStr} required/>

                <TextField type="number" label="Valor" name="valor" value={formData.valor} onChange={handleChange}
                           fullWidth sx={{mb: 2}} required/>

                <TextField label="Tipo" name="tipo" value={formData.tipo} onChange={handleChange} fullWidth
                           sx={{mb: 2}} select>

                    {tipos.map((tipo) => (
                        <MenuItem key={tipo} value={tipo}>
                            {tipo}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField label="Forma de Pagamento" name="formaDePagamento" value={formData.formaDePagamento}
                           onChange={handleChange} fullWidth sx={{mb: 2}} select>

                    {formasDePagamento.map((tipo) => (
                        <MenuItem key={tipo} value={tipo}>
                            {tipo}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField label="Observações" name="observacoes" value={formData.observacoes} onChange={handleChange}
                           multiline rows={4} fullWidth sx={{mb: 2}}/>
                <Button type="submit" variant="contained" color="primary">Inserir Registro</Button>
            </form>
        </Container>
    )
}

export default ContasNew
