import {formatCurrency, formatDate} from "../utils/Utils";
import styles from "./pix.module.css";
import MaskedInputComponent from "../utils/MaskedInputComponent";
import React from "react";

export function getColumns(showValues) {

    return [
        {
            accessorKey: 'detalhe_pagamento.data',
            header: 'Data',
            Cell: ({cell}) => <span>{formatDate(cell.getValue())}</span>
        },
        {
            accessorKey: 'detalhe_pagamento.debitado.nome',
            header: 'pagador'
        },
        {
            accessorKey: 'detalhe_pagamento.valor',
            header: 'Valor',
            Cell: ({cell}) => {
                const value = cell.getValue();
                return (
                    <span
                        className={value !== null && value !== undefined ? (showValues ? styles.show_text : styles.hide_text) : ''}>
                    {formatCurrency(value)}
                </span>
                );
            }
        },
        {
            accessorKey: 'detalhe_pagamento.id_pagamento',
            header: 'Identificação'
        },
        {
            accessorKey: 'detalhe_pagamento.debitado.numero_documento',
            header: 'CPF/CNPJ',
            Cell: ({cell}) => {
                const value = cell.getValue();
                return <MaskedInputComponent value={value}/>;
            }
        },
        {
            accessorKey: 'detalhe_pagamento.valor_tarifa',
            header: 'Valor da Tarifa',
            Cell: ({cell}) => {
                const value = cell.getValue();
                return <span>{formatCurrency(value)}</span>;
            }
        }
    ]
}
