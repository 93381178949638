import {Link as RouterLink, useLocation} from 'react-router-dom';
import logo from '../../assets/logo01.jpg'
import styles from './NavBar.module.css'
import {AppBar, Avatar, Box, Tab, Tabs, Toolbar, useMediaQuery, useTheme} from '@mui/material'
import {useState} from "react";
import DrawerComponent from "./DrawerComponent";
import {pages} from "./Pages";
import {AttachMoney, Dashboard, QrCode} from '@mui/icons-material';


function NavBar() {
    const [value, setValue] = useState();
    const theme = useTheme();
    const isMatch = useMediaQuery(theme.breakpoints.down('md'));

    const userInitials = localStorage.getItem("userInitials");
    const location = useLocation();
    const currentPath = location.pathname;

    const renderIcon = (page) => {
        switch (page) {
            case 'Dashboard':
                return <Dashboard/>;
            case 'Pix':
                return <AttachMoney/>;
            case 'Qrcode':
                return <QrCode/>;
            default:
                return null;
        }
    };

    if (currentPath === "/login") {
        return (<></>)
    } else {
        return (
            <>
                <AppBar sx={{backgroundColor: "#FFF"}} elevation={0}>
                    <Toolbar>
                        <> <img className={styles.img} src={logo} alt="Sonifarma"/></>
                        {isMatch ? (
                            <>
                                <DrawerComponent/>
                            </>
                        ) : (
                            <>
                                <Tabs
                                      value={value}
                                      indicatorColor="primary"
                                      onChange={(e, value) => setValue(value)}
                                >
                                    {pages.map((page, index) => (
                                        <Tab
                                            key={index}
                                            label={
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    {renderIcon(page.page)} {/* Renderize o ícone à esquerda */}
                                                    <span style={{ marginLeft: '8px' }}>{page.page}</span> {/* Renderize o texto com margem */}
                                                </Box>
                                            }
                                            component={RouterLink}
                                            to={page.route}
                                            sx={{
                                                color: '#5ca9ac',
                                                fontWeight: 'bold',
                                                fontSize: '24px',
                                                textTransform: 'none',
                                            }}
                                        />
                                    ))}
                                </Tabs>

                                <Avatar sx={{marginLeft: "auto", bgcolor: "#FF5733"}}>{
                                    userInitials
                                }</Avatar>
                            </>
                        )}
                    </Toolbar>
                </AppBar>

            </>
        )
    }
}

export default NavBar
