import { Button, Container, TextField, Box, Typography, Paper } from "@mui/material";
import { useState } from "react";
import QRCode from "qrcode.react";
import { generateQrCode } from "./services/Pix.service";

function QrCode() {
    const qrCodeSize = 400;
    const [qrCodeData, setQrCodeData] = useState(null);
    const [formData, setFormData] = useState({
        codigoOrcamento: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const codigoOrcamento = formData.codigoOrcamento;
        const fetchedData = await generateQrCode(codigoOrcamento);
        const jsonData = await fetchedData.json();
        setQrCodeData(jsonData.qrCode);
    }

    return (
        <Box
            sx={{
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',

                padding: 2
            }}
        >
            <Paper
                sx={{
                    padding: 4,
                    borderRadius: 2,
                    boxShadow: 3,
                    maxWidth: 600,
                    width: '100%'
                }}
            >
                <Typography variant="h4" gutterBottom textAlign="center">
                    Gerar QR Code
                </Typography>
                <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <TextField
                        type="number"
                        label="Código do Orçamento"
                        name="codigoOrcamento"
                        onChange={handleChange}
                        value={formData.codigoOrcamento}
                        fullWidth
                        required
                        sx={{ marginBottom: 2 }}
                    />
                    <Button type="submit" variant="contained" color="primary" sx={{ width: '100%' }}>
                        Gerar QrCode
                    </Button>
                </form>
                {qrCodeData && (
                    <Box sx={{ marginTop: 4, textAlign: 'center' }}>
                        <QRCode value={qrCodeData} size={qrCodeSize} />
                        <Typography variant="h6" sx={{ marginTop: 2, wordBreak: 'break-word' }}>
                            {qrCodeData}
                        </Typography>
                    </Box>
                )}
            </Paper>
        </Box>
    );
}

export default QrCode;
