import './App.css';
import RoutesComponent from "./components/routes/RoutesComponent";
import {AuthProvider} from "react-auth-kit";


function App() {
    return (
        <AuthProvider
            authType={"cookie"}
            authName={"_auth"}
            cookieDomain={window.location.hostname}
            cookieSecure={false}>
            <RoutesComponent/>
        </AuthProvider>
    );
}

export default App;
