const baseUrl = process.env.REACT_APP_API_CONTAS_URL

const token = localStorage.getItem("token");

export const getPix = async (startDate) => {
    const url = `${baseUrl}/v1/pix/getAll?date=${startDate}`;

    return fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        }
    });
}


export const generateQrCode = async (codigoOrcamento) => {
    const url = `${baseUrl}/qrCode?codigoOrcamento=${codigoOrcamento}`;

    return fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
        }
    });
}

