export const defaultColDef = {
    sortable: true,
    editable: true,
    filter: true,
    resizable: true,
    floatingFilter: true
}

const formatCurrency = (params) => {
    const value = params.value == null ? 0 : params.value;
    return value.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: "currency",
        currency: "BRL"
    });
}

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

function removeLeadingZeros(str) {
    if (str == null || str == undefined) {
        return str
    } else {
        return str.replace(/^0+/, '');
    }
}

export const columnDefs = [
    {headerName: 'Mensagem', field: 'detalhe_pagamento.debitado.nome', editable: false, flex: 1, width: 'auto'},
    {
        headerName: 'Valor',
        field: 'detalhe_pagamento.valor',
        valueFormatter: (params) => formatCurrency(params),
        editable: false,
        flex: 1,
        width: 'auto'
    },
    {
        headerName: 'Data-hora',
        valueFormatter: (params) => formatDate(params.data.detalhe_pagamento.data),
        field: 'detalhe_pagamento.data',
        editable: false,
        flex: 1,
        width: 'auto'
    },
    {
        headerName: 'N Orçamento', field: 'detalhe_pagamento.txid',
        valueFormatter: (params) => removeLeadingZeros(params.data.detalhe_pagamento.txid),
        editable: false, flex: 1, width: 'auto'
    },
    {headerName: 'Comanda Orçamento', field: '', editable: false, flex: 1, width: 'auto'}
];
